import React from "react";
import "../style/Header.css";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import { FaInstagram } from "react-icons/fa";

function Header() {
  const smallScreen = window.innerWidth <= 520;
  const btnText = smallScreen ? "Entrar" : "Entrar na Agência virtual";

  return (
    <nav className="header">
      <header className="header-container">
        <button className="header-button">{btnText}</button>
        <div className="phone-social-media">
          <p className="header-phone">
            <b>Informações e Serviços</b>: 0800 081 0195
          </p>
          <div className="header-social-icons">
            <FaFacebookF />
            <FaTwitter />
            <TfiYoutube />
            <FaInstagram />
          </div>
        </div>
      </header>
    </nav>
  );
}

export default Header;
