// src/Pagina1.js
import React from "react";
import "../style/Pagina1.css";
import logo from "../images/compesa.jpg";
import logoSimples from "../images/logo.png";

import PgCard from "../images/icone_pagamento_cartao.png";
import Titulo from "../images/icone_mudanca_titularidade.png";
import SegVia from "../images/icone_2a_via_conta.png";
import Protocolo from "../images/icone_acomp_protocolos.svg";

function Pagina1() {
  const smallScreen = window.innerWidth <= 520;
  const imagem = smallScreen ? logoSimples : logo;

  return (
    <div className="pagina1-container">
      <div className="pagina1">
        <img src={imagem} alt="Logo" />
        <h1>Agência Virtual</h1>
        <p>≪ Voltar</p>
      </div>
      <div className="icon-buttons">
        <div className="iconSelect star">
          <svg
            id="icon-mais-usados"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
            className="menuIcons star"
          >
            <polygon points="30.21 7.87 37.03 21.69 52.29 23.91 41.25 34.67 43.85 49.87 30.21 42.69 16.56 49.87 19.17 34.67 8.13 23.91 23.38 21.69 30.21 7.87" />
          </svg>
          <p>Mais usados</p>
        </div>
        <div className="iconSelect">
          <svg
            className="menuIcons"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
          >
            <path
              class="cls-1"
              d="M44,19.25a13.5,13.5,0,1,1-13.5-13.5A13.54,13.54,0,0,1,44,19.25Z"
            ></path>
            <path
              class="cls-1"
              d="M54.49,53.87H6.42c3.35-10.43,12.88-18,24-18S51.14,43.44,54.49,53.87Z"
            ></path>
          </svg>
          <p>Cadastro</p>
        </div>
        <div className="iconSelect">
          <svg
            className="menuIcons"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
          >
            <path d="M46.57,32.11,35.64,22.63l-1.33-3.76-3-.6-4,3.6s-2.87,1.25-3.9.28-.51-2.94-.51-2.94l2.73-2.87-2.35-.49-2.78-2.6-11.14,11,6.26,5.68.61,2.24L31.48,46.37a3.21,3.21,0,0,0,3.26.24,2.23,2.23,0,0,0,.35-3.38c-1.21-1.14-6.55-5.74-6.55-5.74s-.81-.61-.55-.94,1.21.38,1.21.38,6.74,6.17,7.44,6.73c.5.41,1.94,1.59,3.26.74s1.53-2.61.64-3.41c-.41-.38-8.42-7.28-8.42-7.28s-.64-.43-.35-.77.94.21.94.21,8,7.18,8.57,7.67a2.43,2.43,0,0,0,3-.19,2.15,2.15,0,0,0-.15-3.18l-8.25-6.93s-.87-.7-.55-1,1.33.57,1.33.57,6,5.32,6.49,5.67a2.72,2.72,0,0,0,3.16-.14A2.44,2.44,0,0,0,46.57,32.11Z"></path>
            <path d="M46.61,33.28,35.16,22.83l-1.08-3.21-2.71-.46c-.56.53-1.76,1.65-3.81,3.47A3.91,3.91,0,0,1,25,23.77a3,3,0,0,1-1.87-.67A3.88,3.88,0,0,1,21.87,19l.07-.24,6.47-6.62,8,0,2.35-2.47L53,23.9l-2.41,2.83-.28,3.81ZM36,22.31l10.61,8.76,1.66-1.48.27-3.67L50.21,24,38.79,12.6l-1.53,1.6-8-.06-5.5,5.63a1.86,1.86,0,0,0,.58,1.74,1,1,0,0,0,.63.25,3,3,0,0,0,1.68-.58c2.89-2.56,3.6-3.16,3.62-3.17l.92-.24,3.52.61Z"></path>
            <path d="M39.25,11.68,50.72,22.94a2.72,2.72,0,0,0,3.85-.06l1.29-1.34a2.7,2.7,0,0,0,0-3.77L44.56,6.35a2.71,2.71,0,0,0-3.85,0l-1.49,1.5A2.7,2.7,0,0,0,39.25,11.68Z"></path>
            <path d="M15.17,8,3.91,19.42A2.7,2.7,0,0,0,4,23.26l1.34,1.29a2.7,2.7,0,0,0,3.77,0L20.5,13.25a2.71,2.71,0,0,0,0-3.85L19,7.92A2.7,2.7,0,0,0,15.17,8Z"></path>
            <rect x="8.51" y="49.01" width="45.93" height="5.86"></rect>
          </svg>
          <p>Negociação</p>
        </div>
        <div className="iconSelect">
          <svg
            className="menuIcons"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
          >
            <rect x="11.35" y="10.8" width="4.62" height="27.07"></rect>
            <rect x="46.79" y="10.8" width="2.77" height="27.07"></rect>
            <rect x="22.75" y="10.8" width="4.93" height="9.39"></rect>
            <rect x="19.05" y="10.8" width="1.85" height="9.39"></rect>
            <rect x="36.31" y="10.8" width="2.77" height="9.39"></rect>
            <rect x="41.86" y="10.8" width="3.08" height="9.39"></rect>
            <rect x="30.46" y="10.8" width="3.7" height="9.39"></rect>
            <path d="M8.25,50.09s0-.26,0-.41V10.8c0-1.66,0-3.08,2.19-3.08H49.88c2.21,0,2.77,1.42,2.77,3.08V49.68c0,.15-.31.41-.31.41h3.08V11.16c0-3.75-2.08-5.29-4.41-5.29H9.58c-2.33,0-4.09,1.54-4.09,3.37V50.09Z"></path>
            <rect
              class="cls-2"
              x="5.49"
              y="52.2"
              width="49.92"
              height="2.67"
            ></rect>
            <path
              class="cls-1"
              d="M32.88,46.06v2.42a.77.77,0,0,1-.84.75H28.8a.76.76,0,0,1-.81-.75V46.16a15.1,15.1,0,0,1-5.15-1.74,1,1,0,0,1-.42-.68.56.56,0,0,1,.08-.35l1.29-2.25a.82.82,0,0,1,.73-.39.87.87,0,0,1,.42.07A11.43,11.43,0,0,0,30.17,42c1.26,0,3-.5,3-1.36,0-2.42-10.61-2.1-10.61-8.87,0-3.13,1.94-5.23,5.45-5.88V23.62a.76.76,0,0,1,.81-.75H32a.77.77,0,0,1,.84.75v2.31A21,21,0,0,1,37,27.07a1,1,0,0,1,.46.64,1.4,1.4,0,0,1,0,.39l-1.21,2.64a1.14,1.14,0,0,1-.81.5l-.42-.11a23.64,23.64,0,0,0-4.39-.75c-2.48,0-2.67,1.14-2.67,1.39,0,2.43,10.61,2.57,10.61,8.84C38.49,43.49,35.67,45.38,32.88,46.06Z"
            ></path>
          </svg>
          <p>Conta</p>
        </div>
        <div className="iconSelect">
          <svg
            className="menuIcons"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
          >
            <path
              class="cls-1"
              d="M26.46,13.56C26.38,9.05,22.67,5.8,18,5.8S9.52,8.85,9.53,13.36a11.15,11.15,0,0,0,1.06,4.55l7.62,12.33,7.19-12A9.91,9.91,0,0,0,26.46,13.56ZM18,18.3a4,4,0,0,1-4.09-3.94,4.08,4.08,0,0,1,8.16,0A4,4,0,0,1,18,18.3Zm30.7,5.39L22.36,23.3l-4.15,6.94-5.47-8.85-3.65,15h3V54.87H36.89V40h7.87V54.87H48.7V36.4h4.06L49.68,23.69ZM31.14,50H17.82V40H31.14Zm-1.3-34.15h16.5l0,7.78L25.5,23.41Z"
            ></path>
          </svg>
          <p>Atendimento</p>
        </div>
        <div className="iconSelect">
          <svg
            className="menuIcons"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
          >
            <path
              class="cls-1"
              d="M47.52,43.7S44,48,44,51.37a3.5,3.5,0,1,0,7,0c0-3.39-3.49-7.67-3.49-7.67Z"
            ></path>
            <polygon
              class="cls-1"
              points="23.88 22.65 33.15 22.65 33.15 17.61 39.05 17.61 39.05 14.24 33.15 14.24 33.15 10.87 23.88 10.87 23.88 14.24 18.82 14.24 18.82 17.61 23.88 17.61 23.88 22.65"
            ></polygon>
            <path
              class="cls-1"
              d="M41.14,26H37a30,30,0,0,1-2.55-3.39H23c-.67,1.28-1.31,2.27-2,3.39H7.87v11.8H21.53a9.17,9.17,0,0,0,15,0h3.06c1.3,0,2,.21,2,1.52v2.69H51.69V37.78C51.69,31.24,47.68,26,41.14,26Z"
            ></path>
          </svg>
          <p>Abastecimento</p>
        </div>
        <div className="iconSelect">
          <svg
            className="menuIcons"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.91 60.91"
          >
            <polygon points="8.46 13.87 52.46 13.87 52.46 17.87 30.46 32.87 8.46 17.87 8.46 13.87"></polygon>
            <polygon points="34.22 34.15 52.46 47.87 52.46 21.85 34.22 34.15"></polygon>
            <polygon points="8.46 21.85 8.46 47.87 26.04 33.98 8.46 21.85"></polygon>
            <polygon points="34.22 34.15 30.46 36.69 26.04 33.98 8.46 47.87 52.46 47.87 34.22 34.15"></polygon>
          </svg>
          <p>Contato</p>
        </div>
      </div>
      <div className="highlight-buttons">
        <div className="div-4-bototes">
          <img src={PgCard} alt="Ícone Destaque 1" />
          <p>Pagamento por Cartão</p>
        </div>
        <div className="div-4-bototes">
          <img src={Titulo} alt="Ícone Destaque 2" />
          <p>Mudança de Titularidade</p>
        </div>
        <div className="div-4-bototes">
          <img src={SegVia} alt="Ícone Destaque 3" />
          <p>2ª Via da Conta</p>
        </div>
        <div className="div-4-bototes">
          <img src={Protocolo} alt="Ícone Destaque 4" />
          <p>Acompanhamento de Protocolos</p>
        </div>
      </div>
    </div>
  );
}

export default Pagina1;
