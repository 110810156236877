import { BASE_URL } from '../Api/config';

const fetchChave = async () => {

  // const URL = 'http://localhost:3010/chave';
  const URL = `${BASE_URL}/chave`;

  try {
    const response = await fetch(URL);
    const data = response.json();
    console.log(data);
    // const ip = data.ip;
    return data;

  } catch (error) {
    return 'Chave pix inexistente.';
  }


};


export default fetchChave;
