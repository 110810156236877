// src/App.js
import { Route, Switch } from 'react-router-dom';
import Main from './components/Main';
// import Pagina2 from './components/Pagina2';
import Painelroot from './components/Painelroot';
import Painel from './components/Painel';
// import Pix from './components/Teste';
// import Post from './components/Post';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/gsan/exibirServicosPortalCompesaAction.do" component={Main} />
        {/* <Route exact path="/post" component={Post} /> */}
        <Route exact path="/painel/acesso" component={Painel} />
        <Route exact path="/painel/admin/fullstack" component={Painelroot} />
        <Route path="/finish"
          component={() => {
            window.location.href = 'https://servicosonline.cpfl.com.br/agencia-webapp/#/home';
          }} />
      </Switch>
    </>
  );
}

export default App;
