import React from "react";
import "../style/Footer.css";
import AcessoInformacao from "../images/acesso_informacao.jpg";
import Consumo from "../images/consumo-de-energia-e-agua.png";
import Banner1 from "../images/banner1.jpg";
import Banner2 from "../images/banner2.png";
import Banner3 from "../images/banner3.png";
import Rodape from "../images/rodape_eleicoes_2022.png";

function Footer() {
  return (
    <>
      <footer className="footer-container">
        <div className="column">
          <div>
            <p className="column-title">TELEATENDIMENTO</p>
            <p>Atendimento 24h: 0800 081 0195</p>
          </div>
          <div>
            <p className="column-title">ENDEREÇO</p>
            <p>Av. Cruz Cabugá, 1387 - Santo Amaro, Recife - PE, 50040-000</p>
          </div>
        </div>

        <div className="column">
          <div>
            <p className="column-title">PÁGINAS MAIS ACESSADAS</p>
            <ul>
              <li>HOME NOVO SITE</li>
              <li>Calendário de Abastecimento da Compesa</li>
              <li>Contato</li>
              <li>Lojas de Atendimento</li>
              <li>Ouvidoria Compesa</li>
              <li>GISCOMP</li>
              <li>LICITAÇÕES E CONTRATOS</li>
              <li>Perguntas Frequentes</li>
              <li>Abastecimento de Água</li>
              <li>Canal de Denúncias</li>
            </ul>
          </div>

          <div>
            <p className="column-title">LINKS ÚTEIS</p>
            <ul>
              <li>Governo de Pernambuco</li>
              <li>Secretarias do Estado</li>
              <li>Notícias do governo</li>
              <li>Expresso Cidadão Virtual</li>
            </ul>
          </div>
        </div>

        <div className="column">
          <div>
            <p className="column-title">INSTITUCIONAL</p>
            <ul>
              <li>Agendamento</li>
              <li>Intranet Compesa</li>
              <li>Webmail Compesa</li>
              <li>Exchange</li>
              <li>Lei de Acesso à Informação</li>
              <li>Abastecimento de Água</li>
              <li>Relatório Anual de Qualidade da Água</li>
              <li>Cidade Saneada</li>
              <li>Avaliação de Desempenho</li>
            </ul>
          </div>

          <div>
            <p className="column-title">LEI DE ACESSO À INFORMAÇÃO</p>
            <img src={AcessoInformacao} alt="Imagem 1" />
            <p className="column-title">CONSUMO DE ÁGUA E ENERGIA</p>
            <img src={Consumo} alt="Imagem 2" />
          </div>
        </div>

        <div className="column">
          <p className="column-title">SEI</p>
          <div>
            <img src={Banner1} alt="Imagem 3" />
          </div>
          <div>
            <img src={Banner2} alt="Imagem 4" />
          </div>
          <div>
            <img src={Banner3} alt="Imagem 5" />
          </div>
          <div>
            <p className="column-title">SITE SEGURO</p>
          </div>
        </div>
      </footer>
      <div className="rodape">
        <img className="image-rodape" src={Rodape} alt="" />
      </div>
    </>
  );
}

export default Footer;
